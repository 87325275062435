export default {
  TYPE_COST: 'cost',
  TYPE_INCOME: 'income',
  TYPE_TAX: 'tax',
  TYPES: [
    'cost',
    'income',
    'tax',
  ],
  
  COST_TYPES: [
    'food', // 食
    'clothing', // 衣
    'housing', // 住
    'transportation', // 行
    'education', // 育
    'entertainment', // 樂
    'social', // 交際
    'medical', // 醫療
    'parenting', // 育兒
    'insurance', // 保險
    'communication', // 通訊
    'luxury', // 奢侈
    'self-investment', // 自我投資
    'business', // 商業開銷
    'others', // 雜
  ],
  
  COST_TYPE_FOOD: 'food',
  COST_TYPE_CLOTHING: 'clothing',
  COST_TYPE_HOUSING: 'housing',
  COST_TYPE_TRANSPORTATION: 'transportation',
  COST_TYPE_EDUCATION: 'education',
  COST_TYPE_ENTERTAINMENT: 'entertainment',
  COST_TYPE_SOCIAL: 'social',
  COST_TYPE_MEDICAL: 'medical',
  COST_TYPE_PARENTING: 'parenting',
  COST_TYPE_INSURANCE: 'insurance',
  COST_TYPE_COMMUNICATION: 'communication',
  COST_TYPE_LUXURY: 'luxury',
  COST_TYPE_SELF_INVESTMENT: 'self-investment',
  COST_TYPE_BUSINESS: 'business',
  COST_TYPE_OTHERS: 'others',

  COLOR_CLASS_COST: 'blue lighten-2',
  COLOR_CLASS_INCOME: 'green lighten-1',
  COLOR_CLASS_TAX: 'red lighten-1',
}