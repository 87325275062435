export default {
  'page.accounting': '記帳',
  'page.search-records': '搜尋記錄',
  'module.accounting': '記帳',

  // data
  'cost.type.null': '未設定',
  'cost.type.food': '食',
  'cost.type.clothing': '衣',
  'cost.type.self-investment': '自我投資',
  'cost.type.business': '商業開銷',
  'cost.type.housing': '住',
  'cost.type.transportation': '行',
  'cost.type.education': '育',
  'cost.type.entertainment': '樂',
  'cost.type.social': '交際',
  'cost.type.medical': '醫療',
  'cost.type.parenting': '育兒',
  'cost.type.insurance': '保險',
  'cost.type.communication': '通訊',
  'cost.type.luxury': '奢侈',
  'cost.type.others': '雜',
  'record.type.income': '收入',
  'record.type.cost': '開銷',
  'record.type.tax': '稅務',
  'income.master.true': '主要收入',
  'income.master.false': '額外收入',

  // form
  // 'accounting.form_block.foobar': 'foobar',

  // others
}
